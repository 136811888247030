import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {Suspense, lazy} from 'react'
import {locationSelector, queryStringObjectSelector} from '../store/routerSelectors'
import useTypedSelector from '../hooks/use-typed-selector'
import {activeLocationSelector} from '../store/state/locations/selectors'
import {Location} from '../store/state/locations/state'
import {isUserAllowed} from '../values/Role'
import {rolesSelector} from '../store/state/roles/selectors'
import {isInternalUserSelector} from '../store/state/current-user/selectors'
import {fleetConfigSelector} from '../store/state/config/selectors'
import {WrapSuspense} from './wrap-suspense'
import {ScrollablePageTemplate} from '../templates/scrollable-page/scrollable-page-template'
import {CenteredContentArea} from '../components/centered-content-area.styled'
import {FixedPageTemplate} from '../templates/fixed-page/fixed-page-template'

const LazyDashboardPage = lazy(() => import('../pages/dashboard-v2/dashboard'))
const LazyEngineStatusPage = lazy(() => import('../pages/system-page/engine-status-page'))
const LazyMonaEngineStatusPage = lazy(() => import('../pages/system-page/mona-engine-mode-page'))
const LazySelfMonitoringEngineStatusPage = lazy(
    () => import('../pages/system-page/self-monitoring-engine-status-page'),
)
const LazyEmailRecipientsManagementPage = lazy(
    () => import('../pages/system-page/email-recipients-management-page'),
)
const LazySwaggerLinksPage = lazy(() => import('../pages/swagger-links-page/swagger-links-page'))
const LazyLicensesPage = lazy(() => import('../pages/licenses/licenses-page'))
const LazyUserManagementPage = lazy(() => import('../pages/user-management/user-management'))
const LazyVesselConfigurationPage = lazy(
    () => import('../pages/vessels/configuration/vessel-configuration'),
)
const LazyVesselsPage = lazy(() => import('../pages/vessels/listing/vessels'))
const LazyMyVesselsDeepLinkingPage = lazy(
    () => import('../pages/my-vessels-v2/deep-linking/my-vessels-deep-linking'),
)
const LazyMyVesselsPage = lazy(() => import('../pages/my-vessels-v2/listing/my-vessels'))
const LazyPolicyManagementPage = lazy(() => import('../pages/policy-management/policy-management'))
const LazyVesselManagementPage = lazy(() => import('../pages/vessel-management/vessel-management'))
const LazyIncidentsBetaPage = lazy(() => import('../pages/incidents-v3/incidents'))
const LazyReportsPage = lazy(() => import('../pages/reports-v2/reports'))
const LazyUnknownAssetPage = lazy(() => import('../pages/unknown-assets-v2/unknown-assets-page'))
const LazyUsbDevicesPage = lazy(() => import('../pages/usb-devices/usb-devices-page'))
const LazySoftwareInventoryPage = lazy(
    () => import('../pages/software-inventory/software-inventory-page'),
)
const LazyNMEAInventoryPage = lazy(() => import('../pages/nmea/nmea-page'))
const LazyMetricsBetaPage = lazy(() => import('../pages/metrics-beta/metrics-beta-page'))
const LazyLocationOTInventoryPage = lazy(
    () => import('../pages/ot-inventory/location-inventory-page'),
)
const LazyVesselsBetaPage = lazy(() => import('../pages/vessels-beta/vessels-beta-page'))
const LazyVesselListPage = lazy(() => import('../pages/ot-inventory/vessel-list-page'))
const LazyIncidentsReportPage = lazy(
    () => import('../pages/incidents-report/incidents-report-page'),
)

const LazyOperationalDashboardPage = lazy(
    () => import('../pages/operational-dashboard/operational-dashboard-page'),
)

const LazyAssetDetailsModal = lazy(() => import('../modals/asset-details/asset-details-modal'))
const LazyEventDetailsProvider = lazy(
    () => import('../modals/event-details/contexts/event-details-provider'),
)
const LazyEventDetailsModal = lazy(() => import('../modals/event-details/event-details-modal'))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getRedirectUrl(location: any): string {
    const {from} = location.state || {from: {pathname: '/dashboard'}}
    const url = from.search ? `${from.pathname}/${from.search}` : `${from.pathname}`
    return url
}

function legacyUpdateToWorkWithV1Layout(activeLocation: Location) {
    const root = document.getElementById('root')
    if (root && activeLocation) {
        if (activeLocation.code) {
            root.className = 'root--selected-location'
        } else if (activeLocation.location === 'fleet-view') {
            root.className = ''
        }
    }
}

export function AuthenticatedRoutes(): JSX.Element {
    const queryString = useTypedSelector(queryStringObjectSelector)
    const displayAssetDetailsModal = queryString?.hasOwnProperty('assetDetails')
    const displayEventDetailsModal =
        displayAssetDetailsModal &&
        queryString?.hasOwnProperty('eventDetails') &&
        queryString?.hasOwnProperty('eventTimestamp')

    const pathname = useLocation().pathname
    const location = useTypedSelector(locationSelector)
    legacyUpdateToWorkWithV1Layout(useTypedSelector(activeLocationSelector))
    const userRoles = useTypedSelector(rolesSelector)
    const isInternalUser = useTypedSelector(isInternalUserSelector)
    const isUserRoleAllowed = isUserAllowed(userRoles, ['DEVELOPER', 'THREAT_ANALYST'])
    const fleetConfig = useTypedSelector(fleetConfigSelector)
    const isAllowed = isInternalUser && isUserRoleAllowed
    const isAllowedToPolicyManagement = isUserAllowed(userRoles, [
        'DEVELOPER',
        'THREAT_ANALYST',
        'CUSTOMER_ADMIN',
    ])

    if (pathname.startsWith('/login')) {
        return <Redirect to={getRedirectUrl(location)} />
    }
    if (pathname.startsWith('/adminLogin')) {
        return <Redirect to={getRedirectUrl(location)} />
    }
    if (pathname.startsWith('/my-vessels-beta')) {
        return <Redirect to={'/my-vessels'} />
    }
    if (pathname.startsWith('/reports-beta')) {
        return <Redirect to={'/reports'} />
    }
    if (pathname.startsWith('/incidentsbeta')) {
        return <Redirect to={'/incidents'} />
    }
    if (pathname.startsWith('/incidents-beta')) {
        return <Redirect to={'/incidents'} />
    }
    if (pathname.startsWith('/dashboardbeta')) {
        return <Redirect to={'/dashboard'} />
    }
    if (location?.search.match('assetManagementExpanded=true')) {
        return <Redirect to={'/network-assets'} />
    }
    if (pathname.startsWith('/unknown-assets-beta')) {
        return <Redirect to={'/network-assets'} />
    }
    if (pathname.startsWith('/unknown-assets')) {
        return <Redirect to={'/network-assets'} />
    }
    if (pathname.startsWith('/medullastatus')) {
        return <Redirect to={'/engine-status'} />
    }
    if (pathname.startsWith('/metrics-beta')) {
        return <Redirect to={'/metrics'} />
    }

    return (
        <>
            <Switch>
                <Route
                    path="/dashboard"
                    render={() => (
                        <ScrollablePageTemplate>
                            <CenteredContentArea>
                                <WrapSuspense>
                                    <LazyDashboardPage />
                                </WrapSuspense>
                            </CenteredContentArea>
                        </ScrollablePageTemplate>
                    )}
                />
                {isAllowed && (
                    <Route
                        path="/engine-status"
                        render={() => (
                            <FixedPageTemplate>
                                <WrapSuspense>
                                    <LazyEngineStatusPage />
                                </WrapSuspense>
                            </FixedPageTemplate>
                        )}
                    />
                )}
                {isAllowed && (
                    <Route
                        path="/mona-engine-mode"
                        render={() => (
                            <FixedPageTemplate>
                                <WrapSuspense>
                                    <LazyMonaEngineStatusPage />
                                </WrapSuspense>
                            </FixedPageTemplate>
                        )}
                    />
                )}
                {isAllowed && (
                    <Route
                        path="/self-monitoring-engine-status"
                        render={() => (
                            <FixedPageTemplate>
                                <WrapSuspense>
                                    <LazySelfMonitoringEngineStatusPage />
                                </WrapSuspense>
                            </FixedPageTemplate>
                        )}
                    />
                )}
                {isAllowed && (
                    <Route
                        path="/email-recipients"
                        render={() => (
                            <FixedPageTemplate>
                                <WrapSuspense>
                                    <LazyEmailRecipientsManagementPage />
                                </WrapSuspense>
                            </FixedPageTemplate>
                        )}
                    />
                )}
                {isAllowed && (
                    <Route
                        path="/swagger-links"
                        render={() => (
                            <FixedPageTemplate>
                                <WrapSuspense>
                                    <LazySwaggerLinksPage />
                                </WrapSuspense>
                            </FixedPageTemplate>
                        )}
                    />
                )}

                {isAllowed && (
                    <Route
                        path="/customer-packages"
                        render={() => (
                            <FixedPageTemplate>
                                <WrapSuspense>
                                    <LazyLicensesPage />
                                </WrapSuspense>
                            </FixedPageTemplate>
                        )}
                    />
                )}
                <Route
                    path="/user-management"
                    render={() => (
                        <FixedPageTemplate>
                            <WrapSuspense>
                                <LazyUserManagementPage />
                            </WrapSuspense>
                        </FixedPageTemplate>
                    )}
                />
                {isAllowed && (
                    <Route
                        path="/vessels/:location"
                        render={(prop) => (
                            <ScrollablePageTemplate>
                                <WrapSuspense>
                                    <LazyVesselConfigurationPage {...prop} />
                                </WrapSuspense>
                            </ScrollablePageTemplate>
                        )}
                    />
                )}
                {isAllowed && (
                    <Route
                        path="/vessels"
                        render={() => (
                            <ScrollablePageTemplate>
                                <WrapSuspense>
                                    <LazyVesselsPage />
                                </WrapSuspense>
                            </ScrollablePageTemplate>
                        )}
                    />
                )}
                <Route
                    path="/my-vessels/:location"
                    render={(prop) => (
                        <ScrollablePageTemplate>
                            <WrapSuspense>
                                <LazyMyVesselsDeepLinkingPage {...prop} />
                            </WrapSuspense>
                        </ScrollablePageTemplate>
                    )}
                />
                <Route
                    path="/my-vessels"
                    render={() => (
                        <WrapSuspense fullScreen>
                            <LazyMyVesselsPage />
                        </WrapSuspense>
                    )}
                />
                {isAllowedToPolicyManagement && (
                    <Route
                        path="/policy-management"
                        render={() => (
                            <FixedPageTemplate>
                                <WrapSuspense>
                                    <LazyPolicyManagementPage />
                                </WrapSuspense>
                            </FixedPageTemplate>
                        )}
                    />
                )}
                {isAllowedToPolicyManagement && (
                    <Route
                        path="/vessel-management"
                        render={() => (
                            <WrapSuspense fullScreen>
                                <LazyVesselManagementPage />
                            </WrapSuspense>
                        )}
                    />
                )}
                <Route
                    path="/incidents"
                    render={() => (
                        <FixedPageTemplate>
                            <WrapSuspense>
                                <LazyIncidentsBetaPage />
                            </WrapSuspense>
                        </FixedPageTemplate>
                    )}
                />
                {(isInternalUser || fleetConfig.reports) && (
                    <Route
                        path="/reports"
                        render={() => (
                            <WrapSuspense fullScreen>
                                <LazyReportsPage />
                            </WrapSuspense>
                        )}
                    />
                )}
                {(isInternalUser || fleetConfig.networkAssets) && (
                    <Route
                        path="/network-assets"
                        render={() => (
                            <WrapSuspense fullScreen>
                                <LazyUnknownAssetPage />
                            </WrapSuspense>
                        )}
                    />
                )}
                {(isInternalUser || fleetConfig.usbInventory) && (
                    <Route
                        path="/usb-devices"
                        render={() => (
                            <WrapSuspense fullScreen>
                                <LazyUsbDevicesPage />
                            </WrapSuspense>
                        )}
                    />
                )}
                {(isInternalUser || fleetConfig.softwareInventory) && (
                    <Route
                        path="/software"
                        render={() => (
                            <WrapSuspense fullScreen>
                                <LazySoftwareInventoryPage />
                            </WrapSuspense>
                        )}
                    />
                )}
                {(isInternalUser || fleetConfig.nmeaInventory) && (
                    <Route
                        path="/nmea"
                        render={() => (
                            <FixedPageTemplate>
                                <WrapSuspense>
                                    <LazyNMEAInventoryPage />
                                </WrapSuspense>
                            </FixedPageTemplate>
                        )}
                    />
                )}
                <Route
                    path="/metrics"
                    render={() => (
                        <WrapSuspense fullScreen>
                            <LazyMetricsBetaPage />
                        </WrapSuspense>
                    )}
                />
                {(isInternalUser ||
                    true) /* TODO: Should be replaced with fleet config when it's ready */ && (
                    <Route
                        path="/ot-inventory/:location/assets"
                        render={(prop) => (
                            <ScrollablePageTemplate>
                                <WrapSuspense>
                                    <LazyLocationOTInventoryPage {...prop} />
                                </WrapSuspense>
                            </ScrollablePageTemplate>
                        )}
                    />
                )}
                <Route
                    path="/vessels-beta"
                    render={() => (
                        <FixedPageTemplate>
                            <WrapSuspense>
                                <LazyVesselsBetaPage />
                            </WrapSuspense>
                        </FixedPageTemplate>
                    )}
                />

                {(isInternalUser ||
                    true) /* TODO: Should be replaced with fleet config when it's ready */ && (
                    <Route
                        path="/ot-inventory-vessels"
                        render={() => (
                            <ScrollablePageTemplate>
                                <WrapSuspense>
                                    <LazyVesselListPage />
                                </WrapSuspense>
                            </ScrollablePageTemplate>
                        )}
                    />
                )}
                <Route
                    path="/incidentsReport"
                    render={() => (
                        <FixedPageTemplate>
                            <WrapSuspense>
                                <LazyIncidentsReportPage />
                            </WrapSuspense>
                        </FixedPageTemplate>
                    )}
                />

                <Route
                    path="/operational-dashboard"
                    render={() => (
                        <ScrollablePageTemplate>
                            <WrapSuspense>
                                <LazyOperationalDashboardPage />
                            </WrapSuspense>
                        </ScrollablePageTemplate>
                    )}
                />
                <Route path="/" render={() => <Redirect to="/dashboard" />} />
            </Switch>

            {displayAssetDetailsModal && (
                <Suspense fallback={<div />}>
                    <LazyAssetDetailsModal />
                    <LazyEventDetailsProvider>
                        {displayEventDetailsModal && <LazyEventDetailsModal />}
                    </LazyEventDetailsProvider>
                </Suspense>
            )}
        </>
    )
}
