import * as Styled from './styles/actions-footer.styled'
import * as StyledAdmin from './styles/admin-sign-in-button.styled'
import {AdminProps} from '../../login-page'
import {AdminSignInButton} from './admin-sign-in-button'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {gfcFlagSelector} from '../../../../store/state/config/selectors'
import {AdminSignInButtonGFC} from './admin-sign-in-button-gfc'
import {Redirect, useLocation} from 'react-router-dom'
import {useState} from 'react'

export function ActionsFooter(props: AdminProps): JSX.Element {
    const [shouldRedirectAdmin, setShouldRedirectAdmin] = useState(false)
    const location = useLocation()

    const appVersion = `V ${process.env.REACT_APP_MEDULLA_VERSION}${
        process.env.REACT_APP_BUILD_NUMBER ? `+${process.env.REACT_APP_BUILD_NUMBER}` : ''
    }`
    const gfcFlag = useTypedSelector(gfcFlagSelector)

    // due to the lack of the redirect method on v5 react-router-dom
    // resorting to rendering redirect this way with a stored state.
    if (shouldRedirectAdmin) {
        return (
            <Redirect
                to={{
                    pathname: '/adminLogin',
                    state: location.state,
                }}
            ></Redirect>
        )
    }

    if (props.admin) {
        return (
            <Styled.ActionsFooter>
                <AdminSignInButton />
            </Styled.ActionsFooter>
        )
    }

    return (
        <Styled.ActionsFooter>
            <span id="app-version">{appVersion}</span>
            {gfcFlag ? (
                <AdminSignInButtonGFC />
            ) : (
                <StyledAdmin.AdminSignInButton
                    id="admin-signin"
                    onClick={() => setShouldRedirectAdmin(true)}
                >
                    Admin Sign In
                </StyledAdmin.AdminSignInButton>
            )}
        </Styled.ActionsFooter>
    )
}
